import React, { useState } from "react";
import { Col, Card, CardBody, CardHeader, Progress, Row, Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from "reactstrap";
import Moment from 'moment';
import { MDBDataTableV5 } from 'mdbreact';
const Statistics = (props) =>{ 
  
  const statisticsMonth = props.statisticsMonth;

  const today = Moment(Date.now()).format('DD-MM-YYYY');

  const month = props.month;

  const [isWatchingDetail, setIsWatchingDetail] = useState(false);

  const subjects = process.env.REACT_APP_SUBJECTS.split(",");

  const datatable = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
      },
      {
        label: 'Họ & Tên',
        field: 'lastName',
        sort: 'asc',
      },
      {
        label: '',
        field: 'type',
        sort: 'asc',
      },
      {
        label: 'Đã đóng',
        field: 'studentNumber',
        sort: 'asc',
      },
      {
        label: 'Môn học',
        field: 'startDate',
        sort: 'asc',
      },
      {
        label: 'Thời gian đóng tiền',
        field: 'grade',
        sort: 'asc',
 
      },
      {
        label: 'Người thêm',
        field: 'parentName',
        sort: 'asc',
      },
      {
        label: 'Chỉnh sửa lần cuối',
        field: 'parentNumber',
        sort: 'asc',
      },
    ],
    rows: [
       
    ]
  }; 
  
  return(
  <Row>
    <Col lg="3" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-primary float-right">{today}</span>
          <h5 className="card-title mb-0">Tổng học phí đã thu</h5>
        </CardHeader>
        <CardBody className="my-2">
          <Row className="d-flex align-items-center mb-4">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                +103515K
              </h2>
            </Col>
            <Col xs="4" className="text-right">
                <button
                 onClick={() => setIsWatchingDetail(true)}
                 style={{
                  "border": "none",
                  "background": "lightskyblue",
                  "borderRadius": "5px"
                }}>Xem chi tiết</button>
            </Col>
          </Row>
          <Progress
            color="primary"
            value="100"
            className="progress-sm shadow-sm mb-1"
          />
         
        </CardBody>
      </Card>
    </Col>
    <Col lg="3" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-primary float-right">Tháng {month}</span>
          <h5 className="card-title mb-0">Học sinh mới</h5>
        </CardHeader>
        <CardBody className="my-2">
          <Row className="d-flex align-items-center mb-4">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                +{statisticsMonth[0].numberOfStatisticStudentThisMonth}
              </h2>
            </Col>
            <Col xs="4" className="text-right">
              <span className="text-muted">{ 
              (statisticsMonth[0].percentDeltaCurrentMonth !== -96.6996) ? 
              (statisticsMonth[0].percentDeltaCurrentMonth > 0) ?
               <p style={{fontWeight:"bold", color:"green"}}>+{statisticsMonth[0].percentDeltaCurrentMonth.toFixed(2) +"%"}</p> : 
               <p style={{fontWeight:"bold", color:"red"}}>{statisticsMonth[0].percentDeltaCurrentMonth.toFixed(2) +"%"}</p> :
               <p style={{fontWeight:"bold", fontSize:"25px"}}>&#8734;</p>}
              </span>
            </Col>
          </Row>

          <Progress
            color="primary"
            value={ (statisticsMonth[0].percentDeltaCurrentMonth !== -96.6996) ? statisticsMonth[0].percentDeltaCurrentMonth.toFixed(2) : 100}
            className="progress-sm shadow-sm mb-1"
          />
        </CardBody>
      </Card>
    </Col>
    <Col lg="3" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-warning float-right">Tháng {month}</span>
          <h5 className="card-title mb-0">Học sinh nghỉ</h5>
        </CardHeader>
        <CardBody className="my-2">
          <Row className="d-flex align-items-center mb-4">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                -{statisticsMonth[1].numberOfStatisticStudentThisMonth}
              </h2>
            </Col>
            <Col xs="4" className="text-right">
              <span className="text-muted " >
                {
                  (statisticsMonth[1].percentDeltaCurrentMonth !== -96.6996) ? 
                  (statisticsMonth[1].percentDeltaCurrentMonth < 0) ?
                   <p style={{fontWeight:"bold", color:"green"}}>{statisticsMonth[1].percentDeltaCurrentMonth.toFixed(2) +"%"}</p> : 
                   <p style={{fontWeight:"bold", color:"red"}}>+{statisticsMonth[1].percentDeltaCurrentMonth.toFixed(2) +"%"}</p> :
                   <p style={{fontWeight:"bold", fontSize:"25px"}}>&#8734;</p>
                }
              </span>
            </Col>
          </Row>

          <Progress
            color="warning"
            value={(statisticsMonth[1].percentDeltaCurrentMonth !== -96.6996) ?  statisticsMonth[1].percentDeltaCurrentMonth.toFixed(2) : (statisticsMonth[1].numberOfStatisticStudentThisMonth > 0 ) ? 0 : 100 }
            className="progress-sm shadow-sm mb-1"
          />
        </CardBody>
      </Card>
    </Col>
    <Col lg="3" xl="3">
      <Card className="flex-fill">
        <CardHeader>
          <span className="badge badge-success float-right">Tháng {month}</span>
          <h5 className="card-title mb-0">Tốc độ tăng trưởng</h5>
        </CardHeader>
        <CardBody className="my-2">
          <Row className="d-flex align-items-center mb-4">
            <Col xs="8">
              <h2 className="d-flex align-items-center mb-0 font-weight-light">
                số học sinh: {statisticsMonth[2].numberOfStatisticStudentThisMonth} 
              </h2>
            </Col>
            <Col xs="4" className="text-right">
              <span className="text-muted ">
              {
                  (statisticsMonth[2].percentDeltaCurrentMonth !== -96.6996) ? 
                  (statisticsMonth[2].percentDeltaCurrentMonth > 0) ?
                   <p style={{fontWeight:"bold", color:"green"}}>{statisticsMonth[2].percentDeltaCurrentMonth.toFixed(2) +"%"}</p> : 
                   <p style={{fontWeight:"bold", color:"red"}}>+{statisticsMonth[2].percentDeltaCurrentMonth.toFixed(2) +"%"}</p> :
                   <p style={{fontWeight:"bold", fontSize:"25px"}}>&#8734;</p>
                }
              </span>
            </Col>
          </Row>
          <Progress
            color="success"
            value={(statisticsMonth[2].percentDeltaCurrentMonth !== -96.6996) ? statisticsMonth[2].percentDeltaCurrentMonth.toFixed(2) : 100}
            className="progress-sm shadow-sm mb-1"
          />
        </CardBody>
      </Card>
    </Col>
    <Modal size="xl" isOpen={isWatchingDetail} toggle={setIsWatchingDetail}>
      <ModalHeader>Chi tiết học phí đã thu ngày 24-5-2022</ModalHeader>
      <ModalBody>
      <Row>

        <Col>
        <Card>
            
            <CardBody>

                      
                      <div className="d-flex">
                        <Input type="date"
                            
                        ></Input>
                        <Input
                          id="creator"
                          name="creator"
                          type="select"
                          onChange={ async (e) =>{
                                      // do something
                                    
                                      
                          }}
                          >   
                              <option value="Toán Hình">Tất cả người nhập</option>
                              <option value="Toán Đại">Phạm Thị Hằng</option>
                              <option value="Toán Hình">Nguyễn Hùng Giang</option>
                              <option value="Toán Hình">Nguyễn Thị Đào</option>
                              
                          </Input>
                          <Input
                          id="subject"
                          name="subject"
                          type="select"
                          onChange={ async (e) =>{
                                      // do something
                                    
                                      
                          }}
                          >
                              {subjects.map(s => <option value={s}>{s}</option>)}
                              {/* <option value="Toán Đại">Tất cả môn học</option>
                              <option value="Toán Đại">Toán Đại</option>
                              <option value="Toán Hình">Toán Hình</option>
                              <option value="Toán Hình">Luyện Đề C3</option>
                              <option value="Toán Hình">Luyện Đề C2</option>
                              <option value="Tiếng Anh">Tiếng Anh</option>
                              <option value="Hóa">Hóa</option>
                              <option value="Văn">Văn</option>
                              <option value="Lý">Lý</option> */}
                          </Input>
                      </div>

       
                    <MDBDataTableV5 
                      hover 
                      responsive
                      bordered
                      
                      exportToCSV
                      
                      entriesOptions={[10,15,30,200, 300, 400]}  data={datatable} />
            
                

                

            </CardBody>
            </Card>
          </Col>
        </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between flex-wrap">
          
            <div>
              <Button onClick={() => setIsWatchingDetail(false)} color="primary">Thoát</Button>
              <Button color="primary">Xuất file excel</Button>
            </div>
            <h3 style={{
              fontWeight:"bold"
            }}>Tổng: 12399K</h3>
        
          
        </ModalFooter>
    </Modal>
  </Row>
  );
}
export default Statistics;
