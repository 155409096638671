import React, {useEffect,useState} from "react";

import {
  Col,
  Container,
  Input,
  Row,
  Button,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  ModalHeader,
  Progress
} from "reactstrap";
import Switch from "@material-ui/core/Switch"
import {styled} from "@material-ui/core";
import { MDBDataTableV5 } from 'mdbreact';
import Header from "./Header";
import ClassroomApi from "../../api/ClassroomApi";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import { Filter } from "react-feather";
import { selectFistName } from "../../redux/selectors/userLoginInfoSelector";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import { green } from "@material-ui/core/colors";
import { Box } from "@material-ui/core";
import ManagerApi from "../../api/ManagerApi";
import avatar1 from "../../assets/img/avatars/avatar.jpg";
import { Formik, Form, Field, FastField } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import { toastr } from "react-redux-toastr";
import * as Yup from 'yup';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));



const StudentCostInfo = (props) =>{ 

  const showErrorNotification = (title, message) => {
    const options = {
      timeOut: 10000,
      type: "success",
      showCloseButton: false,
      progressBar: true,
      position: "bottom-center",
    };

    // show notification
    toastr.success(title, message, options);
  };

  const showWarningNotification = (title, message) => {
    const options = {
      timeOut: 10000,
      type: "warning",
      showCloseButton: false,
      progressBar: true,
      position: "bottom-center",
    };

    // show notification
    toastr.warning(title, message, options);
  };

  const datatable = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
      },
      {
        label: '',
        field: 'avatar',
      },
      {
        label: 'Họ & Đệm',
        field: 'lastName',
        sort: 'asc',
      },
      {
        label: 'Tên',
        field: 'firstName',
        sort: 'asc',
      },
      {
        label: 'Trường học',
        field: 'school',
    
      },
      {
        label: 'Học Phí',
        field: 'status',
   
      },
      {
        label: 'Học Phí',
        field: 'subCostStauts',
   
      },
      {
        label: 'Lớp',
        field: 'classroom',

      },
      {
        label: 'SĐT',
        field: 'studentNumber',
   
      },
      {
        label: 'Tên PH',
        field: 'parentName',

      },
      {
        label: 'SĐT PH',
        field: 'parentNumber',

      },
      {
        label: 'Action',
        field: 'action',
   
      },
    ],
    rows: [
       
    ]
  };

  const datatable1 = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
      },
      {
        label: '',
        field: 'avatar',
      },
      {
        label: 'Họ & Đệm',
        field: 'lastName',
        sort: 'asc',
      },
      {
        label: 'Tên',
        field: 'firstName',
        sort: 'asc',
      },
      {
        label: 'Trường học',
        field: 'school',

      },
      {
        label: 'Học Phí',
        field: 'status',

      },
      {
        label: 'Học Phí',
        field: 'subCostStauts',
   
      },
      {
        label: 'Lớp',
        field: 'classroom',
   
      },
      {
        label: 'SĐT',
        field: 'studentNumber',
 
      },
      {
        label: 'Tên PH',
        field: 'parentName',
     
      },
      {
        label: 'SĐT PH',
        field: 'parentNumber',

      },
      {
        label: 'Action',
        field: 'action',

      },
    ],
    rows: [
       
    ]
  }; 

  const subjectss = process.env.REACT_APP_SUBJECTS.split(",");

  const [grade,setGrade] = useState(12);
  const [subject,setSubject] = useState(subjectss[0]);
  const [listActiveStudent, setListActive] = useState([]);
  const [listInActiveStudent, setListInActive] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [success,setSuccess] = useState(false);
  const [isLoadingResetMonth,setIsLoadingResetMonth] = useState(false);
  const [successResetMonth,setSuccesResetMonth] = useState(false);
  const [isDeletingUnPaidStudent, setIsDeletingUnPaidStudent] = useState(false);
  const [isDeletingPaidStudent, setIsDeletingPaidStudent] = useState(false);
  const [deletingStudentCostInfo, setDeletingStudentCostInfo] = useState({});
  const [percentOfPaidStudent, setPercentOfPaidStudent] = useState(0);
  const [checkedCostRequrimentStatus, setCheckedCostRequrimentStatus] = useState(false);
  const [costRequrimentStatus, setCostRequrimentStatus] = useState({});
  const [isCreatingCostRequirement, setIsCreatingCostRequirement] = useState(false);
  const [listCostStatusRequirement, setListCostStatusRequirement] = useState([]);

  const subjects = process.env.REACT_APP_SUBJECTS.split(",");

  console.log(costRequrimentStatus);

  const handleResetCostInfo = async () => {
    var requested = window.confirm("Bạn có chắc chắn muốn tạo lại chu kì học phí mới");
        if(!isLoading && requested){
          setSuccess(false);
          setIsLoading(true);
          const reset = await ManagerApi.resetCostInSubjectInGrade(subject,grade);
          if(reset === "reset successful!"){
            setSuccess(true);
            resetForm();
            setTimeout(() => {
              setIsLoading(false);
            },1000)
          }
        }
  }

  const handleCostRequriedChange = async (event) => {
    if(!checkedCostRequrimentStatus){
      var requested = window.confirm("Bạn có chắc chắn muốn bật yêu cầu đóng học phí, Học sinh phải hoàn thành học phí mới có thể điểm danh vào lớp, yêu cầu này đã bật thì không thể thu hồi lại");
      if ( requested ) {
        const update = await ClassroomApi.enableSubCostStatusForMainCostStauts(grade,subject);
        if(update === "update successful!"){
          showErrorNotification(
            "Bật yêu cầu thành công!",
            "Tất cả học sinh phải hoàn thành học phí mới có thể điểm danh!"
          );
        }
        resetForm();
        setCheckedCostRequrimentStatus(true);
      }
    }else{
      showWarningNotification("Không thể tắt yêu cầu", "vui lòng yêu cầu toàn bộ học sinh hoàn thành đủ học phí để reset chu kì mới")
    }
  }

  const handleResetMonth = async () => {
    var requested = window.confirm("Bạn có chắc chắn muốn xóa các bản điểm danh và bản ghi btvn trước đây 3 tháng ");

    if(!isLoadingResetMonth && requested){
      setSuccesResetMonth(false);
      setIsLoadingResetMonth(true);
      const reset = await ManagerApi.resetMonth();
      if(reset === "reset successful!"){
        setSuccesResetMonth(true);
        setTimeout(() => {
          setIsLoadingResetMonth(false);
          resetForm();
        },1500)
       
      }
    }
  }

  const handleCreateCostRequirement = async () => {
    // call api hiển thị các trạng thái yêu cầu học phí của các môn trong khối
    const res = await ClassroomApi.getAllCostStatusRequirementAtGrade(grade);
    setListCostStatusRequirement(res);
    // mở modal thêm yêu cầu học phí
    setIsCreatingCostRequirement(true);
  }

  const deleteActiveStudentInfo = async (studentId,classId) => {

      if(costRequrimentStatus.costStatus === "inactive"){
                                                                                  // substatus, mainstatus
        const res = await ClassroomApi.changeCostStatusStudent(subject,studentId,"inactive","active");
        if(res === "update successful!"){
          alert("Cập nhật thành công!");
          resetForm();
        }
      }else{
        const res = await ClassroomApi.changeCostStatusStudent(subject,studentId,"inactive","inactive");
        if(res === "update successful!"){
          alert("Cập nhật thành công!");
          resetForm();
        }
      }
      
  }
  const deleteInActiveStudentInfo = async (studentId,classId) => {
  
    const res = await ClassroomApi.changeCostStatusStudent(subject,studentId,"active","active");
    if(res === "update successful!"){
      alert("Cập nhật thành công!");
      resetForm();
    }
  }

  const deleteInActiveStudentInfoWithSubject = async (studentId, listSubjectName) => {
    for (var i = 0 ; i < listSubjectName.length ; i ++){
        console.log(listSubjectName[i])
        const res = await ClassroomApi.changeCostStatusStudent(listSubjectName[i],studentId,"active","active");
        if(res !== "update successful!"){
          alert("Cập nhật "+ listSubjectName[i] + " thất bại!");
          return;
        }
    }
    alert("Cập nhật tất cả các môn học thành công!");
    resetForm();
  
  }

  const deleteActiveStudentInfoWithSubject = async (studentId, listSubjectName) => {
    for (var i = 0 ; i < listSubjectName.length ; i ++){

        if(costRequrimentStatus.costStatus === "inactive"){
          const res = await ClassroomApi.changeCostStatusStudent(listSubjectName[i],studentId,"inactive","active");
          if(res !== "update successful!"){
            alert("Cập nhật "+ listSubjectName[i] + " thất bại!");
            return;
          }
        }else{
          const res = await ClassroomApi.changeCostStatusStudent(listSubjectName[i],studentId,"inactive","inactive");
          if(res !== "update successful!"){
            alert("Cập nhật "+ listSubjectName[i] + " thất bại!");
            return;
          }
        }
        
    }
    alert("Cập nhật tất cả các môn học thành công!");
    resetForm();
  
  }

  const toggleDeleteInActiveStudentInfoForm = async (studentCostInfo) => {
    setIsDeletingUnPaidStudent(true);
    setDeletingStudentCostInfo(studentCostInfo);
  }

  const toggleDeleteActiveStudentInfoForm = async (studentCostInfo) => {
    setIsDeletingPaidStudent(true);
    setDeletingStudentCostInfo(studentCostInfo);
  }

  

  const resetForm = async () => {
          const listActive = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,subject,"active");
          const listInActive = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,subject,"inactive");
          const costRequrimentStatus = await ClassroomApi.getCostStatusOfSubjectInGrade(grade,subject);
          setCostRequrimentStatus(costRequrimentStatus);
          if(costRequrimentStatus.costStatus === "active"){
            setCheckedCostRequrimentStatus(true);
          }else{
            setCheckedCostRequrimentStatus(false)
          }
          setListActive(listActive);
          setListInActive(listInActive);
  }

  useEffect(() => {
    const getCostInfo = async () =>{
          const listActive = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,subject,"active");
          const listInActive = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,subject,"inactive");
          const costRequrimentStatus = await ClassroomApi.getCostStatusOfSubjectInGrade(grade,subject);
          setListActive(listActive);
          setListInActive(listInActive);
          console.log(costRequrimentStatus);
          setCostRequrimentStatus(costRequrimentStatus);
          if(costRequrimentStatus.costStatus === "active"){
            setCheckedCostRequrimentStatus(true);
          }else{
            setCheckedCostRequrimentStatus(false)
          }
    }
    getCostInfo();
    console.log("rerender!");
  }, [grade,subject]);


  useEffect(() => {
    const percentOfInActiveAndAllStudentInSubject = async () => {
    
      if (subject === "Toán Đại"){
          const listActiveMathGeometry = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,"Toán Hình","active");
          const listInActiveMathGeometry  = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,"Toán Hình","inactive");
          const allStudent = listActiveStudent.length + listInActiveStudent.length + listActiveMathGeometry.length + listInActiveMathGeometry.length;
          const numberOfPaidStudentInMath = listActiveStudent.length + listActiveMathGeometry.length;
          if (allStudent !== 0){
            setPercentOfPaidStudent(((numberOfPaidStudentInMath/allStudent) * 100).toFixed(2))
          }
          
      }
      else if (subject === "Toán Hình"){
        const listActiveMathCaculas = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,"Toán Đại","active");
        const listInActiveMathCaculas = await ClassroomApi.getListStudentCostInfoByStatusInGradeAndSubject(grade,"Toán Đại","inactive");
        const allStudent = listActiveMathCaculas.length + listInActiveMathCaculas.length + listActiveStudent.length + listInActiveStudent.length;
        const numberOfPaidStudentInMath = listActiveMathCaculas.length + listActiveStudent.length;
        if (allStudent !== 0){
          setPercentOfPaidStudent(((numberOfPaidStudentInMath/allStudent) * 100).toFixed(2))
        }
        
      }
      else{
        const allStudent = listActiveStudent.length + listInActiveStudent.length
        if (allStudent !== 0){
          setPercentOfPaidStudent(((listActiveStudent.length/allStudent) * 100).toFixed(2))
        }
      }
     

    }
    percentOfInActiveAndAllStudentInSubject();
  }, [grade,subject,listInActiveStudent,listActiveStudent]);

  listInActiveStudent.map(st => datatable.rows.push({
    id: st.id,
    avatar:  <img
                  src={(st.avatarUrl !== null && st.avatarUrl !== "null") ? (`${process.env.REACT_APP_AVATAR_URL}/${st.avatarUrl}`) : 
                    (st.facebookUrl !== null && st.facebookUrl !== "null") ? st.facebookUrl :
                  avatar1 }
                  width="36"
                  height="36"
                  className="rounded-circle mr-2"
                  alt=""
                  />,
    firstName: st.firstName,
    lastName: st.lastName,
    fullName: st.fullName,
    subCostStauts:<Badge color="danger" className="mr-1 my-1">
                      {st.subCostStauts}
                  </Badge>,
    status: <Badge color="danger" className="mr-1 my-1">
                {st.status}
            </Badge>,
    school: st.school,
    grade: st.grade,
    classroom: (st.listClass.map((c,i) =>
                    <div key={i}>
                        {c.grade + c.className}
                        <br/>
                    </div>
                )),
    studentNumber: st.studentNumber,
    parentNumber: st.parentNumber,
    parentName: st.parentName
  }))
  listActiveStudent.map(st => datatable1.rows.push({
    id: st.id,
    avatar:  <img
                  src={(st.avatarUrl !== null && st.avatarUrl !== "null") ? (`${process.env.REACT_APP_AVATAR_URL}/${st.avatarUrl}`) : 
                    (st.facebookUrl !== null && st.facebookUrl !== "null") ? st.facebookUrl :
                  avatar1 }
                  width="36"
                  height="36"
                  className="rounded-circle mr-2"
                  alt=""
                  />,
    firstName: st.firstName,
    lastName: st.lastName,
    fullName: st.fullName,
    subCostStauts:<Badge color="success" className="mr-1 my-1">
                      {st.subCostStauts}
                  </Badge>,
    status: <Badge color="success" className="mr-1 my-1">
              {st.status}
            </Badge>,
    school: st.school,
    grade: st.grade,
    classroom: (st.listClass.map((c,i) =>
                    <div key={i}>
                        {c.grade + c.className}
                        <br/>
                    </div>
                )),
    studentNumber: st.studentNumber,
    parentNumber: st.parentNumber,
    parentName: st.parentName
  }))

  datatable.rows.map(row => 
     row.action = 
     <Button color="primary" style={{borderRadius: "25px"}} 
     onClick={() => {
      if(window.confirm('Bạn có chắc chắn muốn xóa không?')){
        //  setIsDeletingUnPaidStudent(true);
        if(subject === "Toán Đại" || subject === "Toán Hình"){
          toggleDeleteInActiveStudentInfoForm(row);
        }else{
          deleteInActiveStudentInfo(row.id,row.classroomId);
        }
        
       
        //  deleteInActiveStudentInfo(row.id,row.classroomId);
      };
      }}>Delete</Button>  
  )
  datatable1.rows.map(row => 
    row.action = 
    <Button color="primary" 
    style={{borderRadius: "25px"}}
     onClick={() => {
      if(window.confirm('Bạn có chắc chắn muốn xóa không?')){
        if(subject === "Toán Đại" || subject === "Toán Hình"){
          toggleDeleteActiveStudentInfoForm(row);
        }else{
          deleteActiveStudentInfo(row.id,row.classroomId)
        }
        
      };
      }}
     >Delete</Button>  
)
  return(
  <> 
    <Row >
      <Col xs="auto" className="ml-auto text-right mt-n1">
            <button style={{
              border:"none",
              backgroundColor:"#F6F9FC"
            }}>yêu cầu học phí</button>
            <IOSSwitch 
            checked={checkedCostRequrimentStatus}
            onChange={handleCostRequriedChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ m: 1 }}/>
            <Button onClick={() => handleResetCostInfo()} color="primary" className="shadow-sm mr-1">
              <Filter className="feather" /> Chu kỳ mới
            </Button>
            <Button onClick={() => handleResetMonth()} color="primary" className="shadow-sm mr-1">
              <Filter className="feather" /> Reset tháng
            </Button>
            <Button onClick={() => handleCreateCostRequirement()} color="primary" className="shadow-sm mr-1">
               Tạo yêu cầu học phí
            </Button>

      </Col>
        <Modal isOpen={isLoading} toggle={setIsLoading}>
          <ModalBody>
                {!success ? 
                <>
                Đang tạo lại chu kỳ học phí mới.... 
                <CircularProgress />
                </> : <> <CheckIcon style={{color:green[500]}}/> thành công </> }
          </ModalBody>
                    
      </Modal> 
      <Modal isOpen={isLoadingResetMonth} toggle={setIsLoadingResetMonth}>
          <ModalBody>
                {!successResetMonth ? 
                <>
                Đang xóa các bản ghi cách đây 3 tháng... 
                <CircularProgress />
                </> :<Box> <CheckIcon style={{color:green[500]}} /> Xóa thành công </Box> }
          </ModalBody>
                    
      </Modal> 
    </Row>

    <Modal isOpen={isDeletingUnPaidStudent} toggle={setIsDeletingUnPaidStudent}>
        <Formik
            initialValues={
              {
                checked: [],
              }
            }

           
            onSubmit={(values) => {
                console.log(values)
                deleteInActiveStudentInfoWithSubject(deletingStudentCostInfo.id,values.checked);
            }}
        >
        {({setFieldValue, values}) =>
          <Form>
              <ModalHeader>Bạn có muốn xóa thêm những lựa chọn sau?</ModalHeader>
              <ModalBody>
              <Row>
                <Col>
                <Card>
                    <CardBody>
                    <div >
                      <label>
                        <Field type="checkbox" name="checked" value="Toán Đại" />
                        Toán Đại
                      </label>
                      <br/>
                      <label>
                        <Field type="checkbox" name="checked" value="Toán Hình" />
                        Toán Hình
                      </label>
                    </div>
                    </CardBody>
                    </Card>
                  </Col>
                </Row>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between flex-wrap">
                  
                    <div>
                      <Button type="submit" color="primary">Lưu</Button>
                      <Button onClick={() => setIsDeletingUnPaidStudent(false)} color="primary">Thoát</Button>
                    </div>
                </ModalFooter>
            </Form>}
        </Formik>
    </Modal> 

    <Modal isOpen={isCreatingCostRequirement} toggle={setIsCreatingCostRequirement}>
        <Formik
            initialValues={
              {
                  grade: grade,
                  subject: ""
              }
            }

            validationSchema={
              Yup.object({
                subject: Yup.string()
                  .required('bắt buộc'),
                
               
                
                
              })
            }

           
            onSubmit={async (values) => {
                const creation = await ClassroomApi.createCostStatusRequirement("inactive",values.subject,values.grade);
                if (creation === "create successful!"){
                  const res = await ClassroomApi.getAllCostStatusRequirementAtGrade(grade);
                  setListCostStatusRequirement(res);
                  showErrorNotification("Tạo yêu cầu thành công!","Mỗi môn học ở mỗi khối chỉ cần tạo 1 lần duy nhất!");
                }

            }}
        >
        {({setFieldValue, values}) =>
          <Form>
              <ModalHeader>Danh sách trạng thái yêu cầu học phí lớp {grade}</ModalHeader>
              <ModalBody>
              <Row>
                <Col>
                <Card>
                    <CardBody>
                        {/* hiển thị các môn học trong Khối
                        Fromik để thêm môn mới */}
                        
                              {listCostStatusRequirement.map((c,i) => 
                                <Row>
                                  <Col>
                                     <h4>- {c.subject}</h4>
                                  </Col>
                                  <Col>
                                    <IOSSwitch
                                      checked={(c.costStatus === "active") ? true : false}
                                    />
                                  </Col>
                                </Row>
                            )}
                      
                        
                        <Row>
                          <Col>
                                  
                                  <FastField
                                    label="Môn học"
                                    bsSize="lg"
                                    type="select"
                                    name="subject"
                                    id = "subject"
                                    component={ReactstrapInput}
                                  >
                                    {subjects.map(s => <option value={s}>{s}</option>)}
                                    {/* <option value="Toán Đại">Toán Đại</option>
                                    <option value="Toán Hình">Toán Hình</option>
                                    <option value="Toán Hình">Luyện Đề C3</option>
                                    <option value="Toán Hình">Luyện Đề C2</option>
                                    <option value="Tiếng Anh">Tiếng Anh</option>
                                    <option value="Hóa">Hóa</option>
                                    <option value="Văn">Văn</option>
                                    <option value="Lý">Lý</option> */}
                                  </FastField>
                          </Col>
                          <Col>
                    
                                    <FastField 
                                      label="Lớp"
                                      bsSize="lg"
                                      id ="grade"
                                      type="select"
                                      name="grade"
                                      component={ReactstrapInput}
                                    >
                                      <option value = "12">12</option>
                                      <option value = "11">11</option>
                                      <option value = "10">10</option>
                                      <option value = "9">9</option>
                                      <option value = "8">8</option>
                                      <option value = "7">7</option>
                                      <option value = "6">6</option>
                                    </FastField>
                            
                          </Col>
                         
                        </Row>
                    </CardBody>
                    </Card>
                  </Col>
                </Row>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between flex-wrap">
                  
                    <div>
                      <Button type="submit" color="primary">Tạo</Button>
                      <Button onClick={() => setIsCreatingCostRequirement(false)} color="primary">Thoát</Button>
                    </div>
                </ModalFooter>
            </Form>}
        </Formik>
    </Modal> 

    <Modal isOpen={isDeletingPaidStudent} toggle={setIsDeletingPaidStudent}>
        <Formik
            initialValues={
              {
                checked: [],
              }
            }

           
            onSubmit={(values) => {
               
                deleteActiveStudentInfoWithSubject(deletingStudentCostInfo.id,values.checked);
            }}
        >
        {({setFieldValue, values}) =>
          <Form>
              <ModalHeader>Bạn có muốn xóa thêm những lựa chọn sau?</ModalHeader>
              <ModalBody>
              <Row>
                <Col>
                <Card>
                    <CardBody>
                    <div >
                      <label>
                        <Field type="checkbox" name="checked" value="Toán Đại" />
                        Toán Đại
                      </label>
                      <br/>
                      <label>
                        <Field type="checkbox" name="checked" value="Toán Hình" />
                        Toán Hình
                      </label>
                    </div>
                    </CardBody>
                    </Card>
                  </Col>
                </Row>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between flex-wrap">
                  
                    <div>
                      <Button type="submit" color="primary">Lưu</Button>
                      <Button onClick={() => setIsDeletingPaidStudent(false)} color="primary">Thoát</Button>
                    </div>
                </ModalFooter>
            </Form>}
        </Formik>
    </Modal> 

    <Row>
      <Col>
          <Progress
            animated 
            color="warning"
            value={percentOfPaidStudent}
          >{percentOfPaidStudent}</Progress>
      </Col>
    </Row>
    <Row style={{marginTop:"10px"}}>
      <Col>
      
        
            <div style={{display:"flex", justifyContent:"flex-start"}}>
            <h4 style={{fontWeight:"bold"}}>
              Học sinh chưa nộp học phí
            </h4>
            
                  <Row className="ml-auto">
                        <Col  >
                            <Input 
                                  
                      
                                  id ="subject"
                                  type="select"
                                  name="subject"
                                  value={subject}
                                  onChange={ async (e) =>{
                                      setSubject(e.target.value);
                                  }}
                                >
                                  {subjects.map(s => <option value={s}>{s}</option>)}
                                  {/* <option value = "Toán Đại">Toán Đại</option>
                                  <option value = "Toán Hình">Toán Hình</option>
                                  <option value = "Tiếng Anh">Tiếng Anh</option>
                                  <option value = "Lý">Lý</option>
                                  <option value = "Hóa">Hóa</option>
                                  <option value = "Văn">Văn</option> */}
                                
                          </Input>
                        </Col>
                        
                        <Col  >
                            <Input 
                                  id ="grade"
                                  type="select"
                                  name="grade"
                                  value={grade}
                                  onChange={ (e) =>{
                                      setGrade(e.target.value);
                                  }}
                                >
                                  <option value = "12">Khối 12</option>
                                  <option value = "11">Khối 11</option>
                                  <option value = "10">Khối 10</option>
                                  <option value = "9">Khối 9</option>
                                  <option value = "8">Khối 8</option>
                                  <option value = "7">Khối 7</option>
                                  <option value = "6">Khối 6</option>
                          </Input>
                        </Col>
                    </Row>
                
            </div>


              <MDBDataTableV5 
              hover 
              responsive
              bordered
              searchTop
              searchBottom={false}
              entriesOptions={[5,10, 20, 50,100]} entries={10} pagesAmount={10} data={datatable} />
            <br/>

            <div style={{display:"flex", justifyContent:"flex-start", marginTop:"20px"}}>
            <h4 style={{fontWeight:"bold"}}>
              Học sinh đã nộp học phí
            </h4>
            
                  
                
            </div>

  
              <MDBDataTableV5 
                hover
                responsive
                searchTop
                bordered
                searchBottom={false}
                entriesOptions={[5,10, 20, 50,100]} entries={10} pagesAmount={10} data={datatable1} />


      </Col>
    </Row>
  </>
    );
}
const CostInfo = (props) => {

  
 
  return(
    
  <Container fluid className="p-0">
    <Header />
    <Row>
      <Col>
        <StudentCostInfo {...props}/>
      </Col>
    </Row>
  </Container>
  );
}
// const mapGlobalStateToProps = state => {
//   return {
//     students: selectListStudent(state)
//   };
// };
const mapGlobalStateToProps = state => {
  return {
    firstName: selectFistName(state),
    // role: selectRole(state),

  };
};
export default withRouter(connect(mapGlobalStateToProps)(CostInfo));
// export default connect(mapGlobalStateToProps, { getAllStudentAction })(Clients);
// export default CostInfo;